<template>
  <div>
    <instruction-box
      v-if="showTtqwelBanner"
      class="my-4"
      :texts="[
        $t('deskAssessment.results.recommendationsContent.ttqwelBanner.info'),
        $t(
          'deskAssessment.results.recommendationsContent.ttqwelBanner.personal'
        )
      ]"
    />
    <v-card flat class="ma-0 pa-0">
      <v-tabs
        right
        v-model="tab"
        icons-and-text
        color="primary"
        v-if="numberOfRecommendations != 0"
      >
        <v-tabs-slider></v-tabs-slider>

        <v-tab href="#budget">
          <v-tooltip bottom color="transparent">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-icon>attach_money</v-icon>
              </div>
            </template>
            <vitrue-tooltip :message="$t('tooltips.budgetRecommendation')" />
          </v-tooltip>
        </v-tab>

        <v-tab href="#upgrade">
          <v-tooltip bottom color="transparent">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-icon>attach_money</v-icon>
                <v-icon>attach_money</v-icon>
              </div>
            </template>
            <vitrue-tooltip :message="$t('tooltips.upgradeRecommendation')" />
          </v-tooltip>
        </v-tab>
      </v-tabs>

      <v-list-item-content
        v-if="!showConstantRecommendations && numberOfRecommendations > 0"
      >
        <v-list-item-title class="text-h5 text-wrap">
          {{ $t("deskAssessment.results.recommendationsContent.title") }}
        </v-list-item-title>
        <v-list-item-subtitle class="text-wrap">
          {{ $t("deskAssessment.results.recommendationsContent.subtitle") }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-content
        v-if="!showConstantRecommendations && numberOfRecommendations == 0"
      >
        <v-list-item-title class="text-h5 text-wrap">
          {{
            $t(
              "deskAssessment.results.recommendationsContent.perfectScoreTitle"
            )
          }}
        </v-list-item-title>
        <v-list-item-subtitle class="text-wrap">
          {{
            $t(
              "deskAssessment.results.recommendationsContent.perfectScoreSubtitle"
            )
          }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-row
        class="mt-4"
        :justify="$vuetify.breakpoint.smAndUp ? 'start' : 'center'"
      >
        <v-col
          cols="12"
          sm="6"
          lg="4"
          xl="3"
          v-for="card in filteredSetupCards"
          :key="card.id"
        >
          <recommendations-card
            height="100%"
            :id="card.id"
            :linkType="card.linkType(results)"
            :assessmentId="assessmentId"
            :imageName="card.json.imageName"
            :heading="$t(card.json.heading)"
            :subheading="$t(card.json.subheading)"
            :explanation="
              card.explanation
                ? card.explanation(results)
                : $t(card.json.explanation)
            "
            :type="card.json.type"
            :link="linkToShow(card)"
            :emailLink="card.json.emailLink"
            :route="card.json.route"
            :previouslyLiked="likedCards.includes(card.id)"
            :previouslyRequested="previouslyRequested.includes(card.id)"
            :coveredBy="card.json.coveredBy"
            :deskAssessment="results"
          />
        </v-col>
      </v-row>
      <v-row
        class="my-8"
        :class="$vuetify.breakpoint.smAndUp ? 'justify-space-between' : ''"
        :justify="$vuetify.breakpoint.smAndUp ? 'start' : 'center'"
      >
        <v-col
          cols="12"
          lg="6"
          v-for="card in filteredExerciseCards"
          :key="card.json.heading"
        >
          <recommendations-card
            :id="card.id"
            :linkType="card.linkType(results)"
            :assessmentId="assessmentId"
            :link="linkToShow(card)"
            :emailLink="card.json.emailLink"
            :imageName="card.json.imageName"
            :heading="$t(card.json.heading)"
            :subheading="$t(card.json.subheading)"
            :explanation="displayedExplanationText(card)"
            :type="card.json.type"
            :route="card.json.route"
            :previouslyLiked="likedCards.includes(card.id)"
            :coveredBy="card.json.coveredBy"
            :videoDisclaimer="videoDisclaimer"
            :deskAssessment="results"
          />
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import RecommendationsCard from "@/components/common/report/cards/RecommendationsCard";
import {
  getSetupRecommendations,
  getExerciseRecommendations,
  displayedExplanationText,
  getDeskFixedRecommendations,
  sortRecommendationsByCost
} from "@/services/recommendations/recommendations-service.js";
import PregnancyReport from "@/services/deskassessment/pregnancy-report.js";
import { getLikedItemsForAssessment, getEquipmentRequests } from "@/customApi";
import VitrueTooltip from "@/components/common/VitrueTooltip.vue";
import Ribbon from "@/components/common/Ribbon";
import ContentVariantService from "@/services/content/contentVariantService";
import InstructionBox from "@/components/common/InstructionBox.vue";
import { LinkTypes } from "@/services/recommendations/link-types.js";

export default {
  name: "RecommendationsPanel",
  props: {
    results: {},
    assessmentId: null,
    showConstantRecommendations: false,
    showPregnancyContent: Boolean
  },
  components: {
    RecommendationsCard,
    VitrueTooltip,
    Ribbon,
    InstructionBox
  },
  data() {
    return {
      tab: "budget",
      likedCards: [],
      previouslyRequested: []
    };
  },
  async mounted() {
    try {
      this.likedCards = await getLikedItemsForAssessment(this.assessmentId);
      this.previouslyRequested = await getEquipmentRequests(this.assessmentId);
    } catch (err) {}
  },
  computed: {
    ...mapGetters(["customRecommendations"]),
    showTtqwelBanner() {
      // show when not working in office.
      return (
        this.customRecommendations.customRecommendations_ttqWel &&
        this.results.setup.workingfromhome !== 2
      );
    },
    pregnantAndHasPermissionToView() {
      return this.showPregnancyContent && this.results.health.pregnancy === 1;
    },
    numberOfRecommendations() {
      return Object.keys(this.filteredSetupCards).length;
    },
    pregnancyCards() {
      return this.setupCards.filter(card => {
        return card.json.explanation.pregnancy;
      });
    },
    filteredSetupCards() {
      if (this.showConstantRecommendations) {
        return getDeskFixedRecommendations(this.featureFlags);
      }

      var cards = getSetupRecommendations(this.results, this.featureFlags);
      return sortRecommendationsByCost(cards, this.tab);
    },
    filteredExerciseCards() {
      if (
        !this.customRecommendations.customRecommendations_ykavx ||
        this.showConstantRecommendations
      ) {
        return [];
      }

      let exercises = getExerciseRecommendations(
        this.results,
        this.featureFlags
      );

      exercises.forEach(
        item =>
          (item.json = ContentVariantService.getContentVariant(
            item.json,
            this.pregnantAndHasPermissionToView ? "pregnancy" : "general"
          ))
      );
      exercises.forEach(
        item =>
          (item.json = ContentVariantService.getContentVariant(
            item.json,
            this.results.setup.breakActivity === 2
              ? "inactiveBreaks"
              : "noBreaks"
          ))
      );

      return exercises;
    },
    featureFlags() {
      return {
        ...this.customRecommendations,
        pregnancyExerciseVideos: this.pregnantAndHasPermissionToView
      };
    },
    videoDisclaimer() {
      if (this.pregnantAndHasPermissionToView) {
        return this.$t("recommendationsCardComponent.disclaimer.pregnancy");
      }
      return this.$t("recommendationsCardComponent.disclaimer.general");
    }
  },
  methods: {
    displayedExplanationText,
    linkToShow(card) {
      if (card.linkType(this.results) === LinkTypes.VIDEO) {
        return PregnancyReport.getExerciseRecommendationUrl(
          card,
          this.deskAssessment
        );
      }
      return card.json.link;
    }
  }
};
</script>

<style scoped>
.buttonPosition {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -160px;
}
</style>
