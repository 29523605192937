var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "px-2", attrs: { color: "selectedPrimary", flat: "" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "1" } }, [
            _c("div", { staticClass: "bar" })
          ]),
          _c(
            "v-col",
            {
              staticClass: "primary--text font-italic text-body-1",
              attrs: { cols: "11" }
            },
            _vm._l(_vm.texts, function(text, index) {
              return _c("div", { key: index }, [
                _c(
                  "p",
                  { class: _vm.texts.length === index + 1 ? "mb-0" : "" },
                  [_vm._v(" " + _vm._s(text) + " ")]
                )
              ])
            }),
            0
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }