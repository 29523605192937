import { render, staticRenderFns } from "./RecommendationsContent.vue?vue&type=template&id=015b61e8&scoped=true&"
import script from "./RecommendationsContent.vue?vue&type=script&lang=js&"
export * from "./RecommendationsContent.vue?vue&type=script&lang=js&"
import style0 from "./RecommendationsContent.vue?vue&type=style&index=0&id=015b61e8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "015b61e8",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCard,VCol,VIcon,VListItemContent,VListItemSubtitle,VListItemTitle,VRow,VTab,VTabs,VTabsSlider,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src228135001/src/vitrueremote/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('015b61e8')) {
      api.createRecord('015b61e8', component.options)
    } else {
      api.reload('015b61e8', component.options)
    }
    module.hot.accept("./RecommendationsContent.vue?vue&type=template&id=015b61e8&scoped=true&", function () {
      api.rerender('015b61e8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/deskassessment/report/content/RecommendationsContent.vue"
export default component.exports