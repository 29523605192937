import {pickBy, has, forEach, merge} from 'lodash';

export default{
    
    getContentVariant(contentRoot, variantName){
      let contentWithVariant = {};
      let nodesWithVariant = pickBy(contentRoot, (value, key) => has(value, variantName));
      forEach(nodesWithVariant, (value,key) => contentWithVariant[key] = value[variantName]);
      
      let contentWithoutSpecifiedVariant = pickBy(contentRoot, (value, key) => !has(value, variantName));
      
      let mergedContent = merge(contentWithVariant, contentWithoutSpecifiedVariant);
      return mergedContent;
    }
}