var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showTtqwelBanner
        ? _c("instruction-box", {
            staticClass: "my-4",
            attrs: {
              texts: [
                _vm.$t(
                  "deskAssessment.results.recommendationsContent.ttqwelBanner.info"
                ),
                _vm.$t(
                  "deskAssessment.results.recommendationsContent.ttqwelBanner.personal"
                )
              ]
            }
          })
        : _vm._e(),
      _c(
        "v-card",
        { staticClass: "ma-0 pa-0", attrs: { flat: "" } },
        [
          _vm.numberOfRecommendations != 0
            ? _c(
                "v-tabs",
                {
                  attrs: { right: "", "icons-and-text": "", color: "primary" },
                  model: {
                    value: _vm.tab,
                    callback: function($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab"
                  }
                },
                [
                  _c("v-tabs-slider"),
                  _c(
                    "v-tab",
                    { attrs: { href: "#budget" } },
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "", color: "transparent" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "div",
                                      _vm._g(
                                        _vm._b({}, "div", attrs, false),
                                        on
                                      ),
                                      [_c("v-icon", [_vm._v("attach_money")])],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1027233249
                          )
                        },
                        [
                          _c("vitrue-tooltip", {
                            attrs: {
                              message: _vm.$t("tooltips.budgetRecommendation")
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-tab",
                    { attrs: { href: "#upgrade" } },
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "", color: "transparent" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "div",
                                      _vm._g(
                                        _vm._b({}, "div", attrs, false),
                                        on
                                      ),
                                      [
                                        _c("v-icon", [_vm._v("attach_money")]),
                                        _c("v-icon", [_vm._v("attach_money")])
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            322517926
                          )
                        },
                        [
                          _c("vitrue-tooltip", {
                            attrs: {
                              message: _vm.$t("tooltips.upgradeRecommendation")
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          !_vm.showConstantRecommendations && _vm.numberOfRecommendations > 0
            ? _c(
                "v-list-item-content",
                [
                  _c(
                    "v-list-item-title",
                    { staticClass: "text-h5 text-wrap" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "deskAssessment.results.recommendationsContent.title"
                            )
                          ) +
                          " "
                      )
                    ]
                  ),
                  _c("v-list-item-subtitle", { staticClass: "text-wrap" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "deskAssessment.results.recommendationsContent.subtitle"
                          )
                        ) +
                        " "
                    )
                  ])
                ],
                1
              )
            : _vm._e(),
          !_vm.showConstantRecommendations && _vm.numberOfRecommendations == 0
            ? _c(
                "v-list-item-content",
                [
                  _c(
                    "v-list-item-title",
                    { staticClass: "text-h5 text-wrap" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "deskAssessment.results.recommendationsContent.perfectScoreTitle"
                            )
                          ) +
                          " "
                      )
                    ]
                  ),
                  _c("v-list-item-subtitle", { staticClass: "text-wrap" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "deskAssessment.results.recommendationsContent.perfectScoreSubtitle"
                          )
                        ) +
                        " "
                    )
                  ])
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-row",
            {
              staticClass: "mt-4",
              attrs: {
                justify: _vm.$vuetify.breakpoint.smAndUp ? "start" : "center"
              }
            },
            _vm._l(_vm.filteredSetupCards, function(card) {
              return _c(
                "v-col",
                {
                  key: card.id,
                  attrs: { cols: "12", sm: "6", lg: "4", xl: "3" }
                },
                [
                  _c("recommendations-card", {
                    attrs: {
                      height: "100%",
                      id: card.id,
                      linkType: card.linkType(_vm.results),
                      assessmentId: _vm.assessmentId,
                      imageName: card.json.imageName,
                      heading: _vm.$t(card.json.heading),
                      subheading: _vm.$t(card.json.subheading),
                      explanation: card.explanation
                        ? card.explanation(_vm.results)
                        : _vm.$t(card.json.explanation),
                      type: card.json.type,
                      link: _vm.linkToShow(card),
                      emailLink: card.json.emailLink,
                      route: card.json.route,
                      previouslyLiked: _vm.likedCards.includes(card.id),
                      previouslyRequested: _vm.previouslyRequested.includes(
                        card.id
                      ),
                      coveredBy: card.json.coveredBy,
                      deskAssessment: _vm.results
                    }
                  })
                ],
                1
              )
            }),
            1
          ),
          _c(
            "v-row",
            {
              staticClass: "my-8",
              class: _vm.$vuetify.breakpoint.smAndUp
                ? "justify-space-between"
                : "",
              attrs: {
                justify: _vm.$vuetify.breakpoint.smAndUp ? "start" : "center"
              }
            },
            _vm._l(_vm.filteredExerciseCards, function(card) {
              return _c(
                "v-col",
                { key: card.json.heading, attrs: { cols: "12", lg: "6" } },
                [
                  _c("recommendations-card", {
                    attrs: {
                      id: card.id,
                      linkType: card.linkType(_vm.results),
                      assessmentId: _vm.assessmentId,
                      link: _vm.linkToShow(card),
                      emailLink: card.json.emailLink,
                      imageName: card.json.imageName,
                      heading: _vm.$t(card.json.heading),
                      subheading: _vm.$t(card.json.subheading),
                      explanation: _vm.displayedExplanationText(card),
                      type: card.json.type,
                      route: card.json.route,
                      previouslyLiked: _vm.likedCards.includes(card.id),
                      coveredBy: card.json.coveredBy,
                      videoDisclaimer: _vm.videoDisclaimer,
                      deskAssessment: _vm.results
                    }
                  })
                ],
                1
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }