<template>
  <v-card color="selectedPrimary" class="px-2" flat
    ><v-row>
      <v-col cols="1"><div class="bar" /> </v-col>
      <v-col cols="11" class="primary--text font-italic text-body-1">
        <div :key="index" v-for="(text, index) in texts">
          <p :class="texts.length === index + 1 ? 'mb-0' : ''">
            {{ text }}
          </p>
        </div>
      </v-col>
    </v-row></v-card
  >
</template>

<script>
export default {
  name: "InstructionBox",
  props: {
    texts: Array
  }
};
</script>

<style scoped>
.bar {
  width: 0px;
  height: 100%;
  background: var(--v-primary-base);
  border: 2px solid var(--v-primary-base);
  margin-left: auto;
  margin-right: auto;
}
</style>
